<template>
  <div class="instagram">
    <v-row>
      <!-- <v-col cols="3">
        <v-select :items="items" label="Select Account Type" v-model="simulationParams.accountType" solo hide-details></v-select>
      </v-col> -->
      <v-col cols="3">
        <v-select
          :items="categories"
          label="Select Content Type"
          v-model="simulationParams.category"
          item-text="label"
          item-value="value"
          @change="getSimulationData()"
          solo
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          :items="contentTypes"
          label="Select Content Type"
          v-model="simulationParams.contentType"
          item-text="label"
          item-value="value"
          @change="getSimulationData()"
          solo
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="simulationParams.budget"
          placeholder="My Budget"
          solo
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn
          depressed
          dark
          block
          color="primary"
          @click="getSimulationData()"
          :disabled="simulationLoading"
          :loading="simulationLoading"
        >View Stats</v-btn>
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col cols="6">
        <v-card>
          <v-card-text class="d-flex simulation-stats">
            <img
              src="@/assets/images/banner_instagram.png"
              width="144"
            />
            <div
              class="d-flex justify-space-around text-center"
              style="width: 100%;"
            >
              <div>
                <p>
                  <span class="label">Category</span> <br />
                  <span>{{ simulationParams.category }}</span>
                </p>
                <p>
                  <span class="label">Budget</span> <br />
                  <span>{{ simulationParams.budget }}</span>
                </p>
              </div>
              <div>
                <p>
                  <span class="label">Content Type</span> <br />
                  <span>{{ simulationParams.contentType }}</span>
                </p>
                <p>
                  <span class="label">Expected Views</span> <br />
                  <span>{{ simulationData.expectedViews | formatNumber }}</span>
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <apexchart
            type="line"
            height="161"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>

    <!-- <div class="text-center ma-8">
      <img src="@/assets/images/no-campaign.png" width="150" />
    <div class="text-center ma-8">
      <img
        src="@/assets/images/no-campaign.png"
        width="150"
      />

      <h3>No Campaigns Found</h3>
      <v-btn
        depressed
        dark
        large
        color="primary"
        to="/campaign/create"
        style="width: 350px;"
      >Create your First Campaign</v-btn>
    </div> -->

    <div class="d-flex justify-space-between align-center mt-8 mb-6">
      <h4>Campaign Stats</h4>
      <v-btn depressed dark large color="primary" style="width: 252px;" to="/campaign/create">Create Campaign</v-btn>
    </div>

    <v-row class="campaign-stats mb-2">
      <v-col cols="7">
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-text class="d-flex align-center">
                <div class="stat-icon d-flex align-center justify-center">
                  <img src="@/assets/images/icon-view.svg" width="34" />
                </div>
                <div>
                  <p>Total View</p>
                  <h3>0</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-text class="d-flex align-center">
                <div class="stat-icon d-flex align-center justify-center">
                  <img src="@/assets/images/icon-spent.svg" width="29" />
                </div>
                <div>
                  <p>Total Spent</p>
                  <h3>0</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-text class="d-flex align-center">
                <div class="stat-icon d-flex align-center justify-center">
                  <img src="@/assets/images/icon-total-creators.svg" width="31" />
                </div>
                <div>
                  <p>Total Creators</p>
                  <h3>0</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-text class="d-flex align-center">
                <div class="stat-icon d-flex align-center justify-center">
                  <img src="@/assets/images/icon-cpv.svg" width="36" />
                </div>
                <div>
                  <p>Average CPV</p>
                  <h3>0</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-card class="mb-1">
          <v-card-text>
            <img src="@/assets/images/campaign-stats.jpg" width="343" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-card class="mt-8 mb-8 influencer-graph">
      <v-card-text>
        <div class="d-flex justify-start align-start">
          <div style="width: 300px;" class="mr-2">
            <v-select :items="items" label="Total Influencers" v-model="simulationParams.accountType" solo hide-details></v-select>
          </div>
          <div style="width: 160px;" class="mr-2">
            <v-select :items="items" label="Year" v-model="simulationParams.accountType" solo hide-details></v-select>
          </div>
        </div>
      </v-card-text>
    </v-card> -->

  </div>
</template>

<script>
// import { getSimulation } from '@/api/dashboard'

export default {
  data () {
    return {
      categories: [
        {
          label: 'Fashion Beauty',
          value: 'fashion'
        },
        {
          label: 'Entertainment',
          value: 'entertainment'
        },
        {
          label: 'Finance',
          value: 'finance'
        },
        {
          label: 'Gaming',
          value: 'gaming'
        },
        {
          label: 'Tech',
          value: 'tech'
        },
        {
          label: 'Infotainment',
          value: 'infotainment'
        }
      ],
      contentTypes: [
        {
          label: 'Integrated',
          value: 'integrated'
        },
        {
          label: 'Dedicated',
          value: 'dedicated'
        },
        {
          label: 'Shorts',
          value: 'shorts'
        },
        {
          label: 'Live',
          value: 'live'
        }
      ],
      calculationData: {
        fashion: {
          integrated: 1.8,
          dedicated: 3.6,
          shorts: 0.9,
          live: 3.6
        },
        entertainment: {
          integrated: 0.6,
          dedicated: 1.2,
          shorts: 0.3,
          live: 1.2
        },
        finance: {
          integrated: 2.4,
          dedicated: 4.8,
          shorts: 1.2,
          live: 4.8
        },
        gaming: {
          integrated: 1.2,
          dedicated: 2.4,
          shorts: 0.6,
          live: 2.4
        },
        tech: {
          integrated: 1.65,
          dedicated: 3.3,
          shorts: 0.825,
          live: 3.3
        },
        infotainment: {
          integrated: 0.9,
          dedicated: 1.8,
          shorts: 0.45,
          live: 1.8
        }
      },
      simulationLoading: false,
      simulationParams: {
        category: 'entertainment',
        contentType: 'integrated',
        budget: 65000
      },
      simulationData: {
        expectedViews: 0
      },
      chartOptions: {
        chart: {
          id: 'simulation-chart',
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: ['Integrated', 'Dedicated', 'Shorts', 'Live']
        },
        yaxis: {
          labels: {
            formatter: (value) => { return this.$options.filters.formatNumber(value) }
          }
        },
        colors: ['#4C62E3', '#F54153', '#9C27B0'],
        stroke: {
          width: 2,
          curve: 'smooth'
        }
      },
      series: []
    }
  },
  mounted () {
    this.getSimulationData()
  },
  methods: {
    async getSimulationData () {
      this.simulationLoading = true
      this.series = []

      this.simulationData = {
        expectedViews: Number(this.simulationParams.budget / this.calculationData[this.simulationParams.category][this.simulationParams.contentType]).toFixed(0),
        integrated: Number(Number(this.simulationParams.budget / this.calculationData[this.simulationParams.category].integrated).toFixed(0)),
        dedicated: Number(Number(this.simulationParams.budget / this.calculationData[this.simulationParams.category].dedicated).toFixed(0)),
        shorts: Number(Number(this.simulationParams.budget / this.calculationData[this.simulationParams.category].shorts).toFixed(0)),
        live: Number(Number(this.simulationParams.budget / this.calculationData[this.simulationParams.category].live).toFixed(0))
      }

      // pass average calculated views
      const seriesDataAvg = {
        name: 'Expected Views',
        data: [
          this.simulationData.integrated,
          this.simulationData.dedicated,
          this.simulationData.shorts,
          this.simulationData.live
        ]
      }
      this.series.push(seriesDataAvg)

      // pass min calculated views
      // const seriesDataMin = {
      //   name: 'Expected Views (Min)',
      //   data: [
      //     (this.simulationData.integrated - (this.simulationData.integrated / 100 * 20)).toFixed(0),
      //     (this.simulationData.dedicated - (this.simulationData.dedicated / 100 * 20)).toFixed(0),
      //     (this.simulationData.shorts - (this.simulationData.shorts / 100 * 20)).toFixed(0),
      //     (this.simulationData.live - (this.simulationData.live / 100 * 20)).toFixed(0)
      //   ]
      // }
      // this.series.push(seriesDataMin)

      // // pass min calculated views
      // const seriesDataMax = {
      //   name: 'Expected Views (Max)',
      //   data: [
      //     (this.simulationData.integrated + (this.simulationData.integrated / 100 * 20)).toFixed(0),
      //     (this.simulationData.dedicated + (this.simulationData.dedicated / 100 * 20)).toFixed(0),
      //     (this.simulationData.shorts + (this.simulationData.shorts / 100 * 20)).toFixed(0),
      //     (this.simulationData.live + (this.simulationData.live / 100 * 20)).toFixed(0)
      //   ]
      // }
      // this.series.push(seriesDataMax)

      this.simulationLoading = false

      this.$gtag.query('event', 'click', {
        event_category: 'client_console',
        event_label: 'campaign_simulation'
      })
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-weight: 500;
    font-size: 23px;
    line-height: 34px;
    color: #6b6b6b;
    margin: 20px 0;
  }
  .simulation-stats p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin: 15px 0 0;
    text-transform: capitalize;
  }
  .simulation-stats p span.label {
    font-weight: normal;
    color: #6b6b6b;
  }
  .campaign-stats p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin: 0;
  }
  .campaign-stats .stat-icon {
    width: 61px;
    height: 61px;
    background: rgba(245, 65, 83, 0.05);
    border-radius: 50%;
    margin: 0 15px 0 0;
  }
  .influencer-graph >>> .v-select .v-input__control .v-input__slot {
    box-shadow: none !important;
    border-radius: 11px;
    background: #f4f4f4;
  }
  .v-text-field.v-text-field--enclosed.v-text-field--solo {
    margin-top: 0;
  }
</style>
